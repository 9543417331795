<template>
  <DynamicForm
    @submit="submit"
    :schema="formSchemaHttpLink"
    :initial-values="initValues"
    :button-data="httpLinkFormButton"
    form-class="justify-content-start align-items-start"
  ></DynamicForm>
</template>

<script>
import DynamicForm from '@/application/components/forms/DynamicForm'
import { useSenderNameHttpLink } from '@/application/composables/senderNameHttpLink.js'

export default {
  name: 'SenderNameHttpLinkForm',
  components: {
    DynamicForm,
  },
  props: {
    initValues: {
      type: Object,
    },
  },
  emits: ['send-form'],
  setup(props, { emit }) {
    const {
      formSchemaHttpLink,
      httpLinkFormButton,
    } = useSenderNameHttpLink()

    return {
      formSchemaHttpLink,
      httpLinkFormButton,
      submit: (data) => {
        emit('send-form', data)
      },
    }
  },
}
</script>
